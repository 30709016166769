import React from 'react'
import styled from 'styled-components'
import { HideXS } from './mediaQueries'

const StyledVerticalDivider = styled.div`
  background-color: ${({ theme }) => theme.colors.divider};
  height: 100%;
  width: 1px;
  margin-right: 2rem;
`

export const VerticalDivider = () => (
  <HideXS>
    <StyledVerticalDivider />
  </HideXS>
)
