import React from 'react'
import styled from 'styled-components'
import { SideNavigation } from '../components/molecules/SideNavigation'
import { MaxWidthWrapper } from '../components/atoms/MaxWidthWrapper'
import { VerticalDivider } from '../components/atoms/VerticalDivider'

const StyledHeading = styled.h1`
  font-size: 4rem;
  color: ${({ theme }) => theme.colors.heading};
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    margin-top: 7rem;
  }
`

const StyledSubHeading = styled.h2`
  opacity: 0.8;
  font-size: 1.8rem;
  line-height: 1.56;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.default};
  font-weight: 400;
  margin-bottom: 0.5rem;

  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    font-size: 1.6rem;
  }
`

const NotFound = () => {
  return (
    <>
      <SideNavigation />

      <VerticalDivider />

      <MaxWidthWrapper>
        <StyledHeading>404</StyledHeading>
        <StyledSubHeading>Page not found</StyledSubHeading>
      </MaxWidthWrapper>
    </>
  )
}

export default NotFound
